'use client';
import { useRouter } from 'next/navigation';
import React, { useState } from "react";
import toast from "react-hot-toast";
import { signIn } from 'next-auth/react';
import Image from 'next/image';
const Login = () => {
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false); // State to manage login status
  const router = useRouter();
  const backgroundStyle = {
    backgroundImage: "url('/background.svg')", // Ensure the path is correct
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  };
  const handleForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isLoggingIn) return; // Prevent multiple submissions
    setIsLoggingIn(true); // Set login status to true
    const result = await signIn('credentials', {
      redirect: false,
      identifier: email,
      password
    });
    if (result?.error) {
      setIsLoggingIn(false); // Reset login status on error
      if (result.error === 'CredentialsSignin') {
        toast.error('Incorrect username or password');
      } else {
        toast.error(result.error);
      }
    } else {
      toast.success('Login Successfully!');
      router.push("/");
    }
  };
  return (
    <div className="flex justify-center items-center w-full h-screen">
      <div style={backgroundStyle} className="w-1/2 h-full hidden flex-col justify-between p-4 items-center sm:flex">
        <div className="w-full max-w-sm flex justify-center mx-auto mt-5">
          <Image
            src="/bot-image.svg"
            alt="Bot Image"
            width={270}
            height={270}
            // layout="responsive"
          />
        </div>
        <div className=" w-3/4 text-white text-4xl font-semibold font-['DM Sans'] leading-[50px]">Glad to see you back!<br/>Login to continue using botafy</div>
        <div className="text-white text-2xl font-semibold font-['DM Sans'] self-start underline leading-normal">Need Help?</div>
      </div>
      <div className="md:w-1/2 w-full h-full flex flex-col justify-center items-center bg-white text-gray-800 p-10">
    <div className="mx-auto my-24 max-w-md w-full">
        <form onSubmit={handleForm} className="space-y-4">
            <div className="justify-center mb-6 flex">
              <Image src='/botafy-brand.svg' alt="botafy logo" width={150} height={50} />
            </div>
            <input className="w-full px-4 py-3 border-2 border-gray-300 rounded-md focus:border-blue-500 bg-none" id="email" name="email" type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" required />
            <div className="relative">
                <input className="w-full px-4 py-3 border-2 border-gray-300 rounded-md focus:border-blue-500" id="password" name="password" type={showPassword ? "text" : "password"} onChange={(e) => setPassword(e.target.value)} placeholder="Enter your password" required />
                <div className='cursor-pointer absolute right-4 top-4' onClick={() => setShowPassword(prev => !prev)}>
                    <Image src={!showPassword ? '/openeye.svg' : '/closeeye.svg'} alt="show/hide password icon" width={20} height={20} />
                </div>
            </div>
            <p className="text-right text-sm text-[#1570EF] cursor-not-allowed">Forgot your Password?</p>
            <button type="submit" className="w-full py-3 text-lg rounded-full bg-blue-600 text-white border border-blue-500 hover:bg-white hover:text-blue-600">Log in</button>
            <p className="text-sm text-center text-[#98A2B3]">New to Botafy? <a href="/signup" className="text-[#1570EF]">Sign Up</a></p>
        </form>
    </div>
</div>

    </div>
  );
}

export default Login;